export const formDropdownsCleanup = () => {
  const informationForm = document.querySelector('#informationForm') as HTMLFormElement;
  const formErrors = informationForm?.querySelectorAll('.errorlist');
  const isSubmitted = localStorage.getItem('formSubmitted');
  if (formErrors?.length === 0 && isSubmitted) {
    localStorage.removeItem('dropdownInputValue');
    localStorage.removeItem('multiselectInputValue');
    localStorage.removeItem('formSubmitted');
  }
};
