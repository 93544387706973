import { shuffleArray } from './utils';

export function initAboutUs() {
  showTeamMembers();
  activateMap();
  initReadMoreButton();
}

function showTeamMembers() {
  const showAllButton = document.querySelector('.members-button') as HTMLButtonElement;
  const membersContainer = document.querySelector('.members-list') as HTMLElement;
  const memberBlock = document.querySelectorAll('.member') as NodeListOf<HTMLElement>;
  const previewMembersContainer = document.querySelector('.members-list-preview') as HTMLElement;
  const previewMembers = previewMembersContainer.querySelectorAll('.member') as NodeListOf<HTMLElement>;
  let isShowingAll = true;

  if (previewMembers.length > 4) {
    for (let i = 4; i < previewMembers.length; i++) {
      previewMembers[i].style.display = 'none';
    }
  }

  if (showAllButton && membersContainer) {
    showAllButton.addEventListener('click', () => {
      if (isShowingAll) {
        membersContainer.style.display = 'flex';
        showAllButton.textContent = 'Hide';
        previewMembersContainer.style.display = 'none';
        isShowingAll = !isShowingAll;
      } else {
        membersContainer.style.display = 'none';
        previewMembersContainer.style.display = 'flex';
        showAllButton.textContent = 'View All Team';
        isShowingAll = !isShowingAll;
      }
    });
  }

  if (memberBlock.length !== 0 && memberBlock.length < 5) {
    showAllButton.style.display = 'none';
  }
}

function activateMap() {
  const map = document.querySelector('.about-location-map');

  const markerObserver = new IntersectionObserver((entries) => {
    if (entries[0].isIntersecting) {
      map.classList.add('animate');
      changeStateColor();
    }
  });

  if (map) markerObserver.observe(map);
}

function changeStateColor() {
  let states = shuffleArray(Array.from(document.querySelectorAll('.state')));

  states.forEach((state, index) => {
    setTimeout(() => setNewColor(state), index * 50);
  });
}

function setNewColor(state: HTMLElement) {
  state.style.transition = '0.5s';
  state.style.fill = '#E0F4FE';
}

function initReadMoreButton() {
  const contentBlocks = document.querySelectorAll('.about-history-content') as NodeListOf<HTMLElement>;

  contentBlocks.forEach((contentBlock) => {
    const textBlock = contentBlock.querySelector('.text');
    const readMoreButton = contentBlock.querySelector('.read-more') as HTMLElement;
    if (textBlock.clientHeight < 70) readMoreButton.classList.add('hidden');

    readMoreButton.addEventListener('click', () => {
      textBlock.classList.toggle('hidden-text');
      if (textBlock.classList.contains('hidden-text')) {
        readMoreButton.textContent = 'Read more';
      } else {
        readMoreButton.textContent = 'Read less';
      }
    });
    if (window.innerWidth > 768) {
      readMoreButton.classList.add('hidden');
    }
  });
}
