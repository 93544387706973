export const initMoreInfo = () => {
  const moreInfoBtn = document.querySelector('.add-more-button');
  const moreInfoContainer = document.querySelector('.additional-fields');
  if (!moreInfoBtn || !moreInfoContainer) return;

  moreInfoBtn.addEventListener('click', () => {
    moreInfoContainer.classList.add('active');
    moreInfoBtn.classList.add('hidden');
  });
};

export const openMoreInfo = () => {
  const moreInfoContainer = document.querySelector('.additional-fields');
  const moreInfoBtn = document.querySelector('.add-more-button');
  if (!moreInfoBtn || !moreInfoContainer) return;

  moreInfoContainer.classList.add('active');
  moreInfoBtn.classList.add('hidden');
};
