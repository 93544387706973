import IMask from 'imask';

export const PHONE_MASK_CONFIG = '+{1} (000) 000-0000';

export const initPhoneMask = () => {
  const element = document.querySelector('[type="tel"]') as HTMLInputElement;
  const maskOptions = { mask: PHONE_MASK_CONFIG };
  IMask(element, maskOptions);
};

export const toggleSpinner = () => {
  const submitButton = document.getElementById('submitButton');
  submitButton.classList.toggle('loading');
};

export interface DataPayload {
  email: string;
  industry?: string;
  name?: string;
  phone_number?: string;
  note?: string;
  find?: string;
  token: string;
}
