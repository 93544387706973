// @ts-ignore
import Glide from "@glidejs/glide";

export const initClientsSlider = () => {
    const controlBack = document.querySelector(".control-back");
    const controlNext = document.querySelector(".control-next");

    if (!document.querySelector('.glide')) return false;

    const glide = new Glide(".glide", {
        type: "slider",
        perView: 2,
        breakpoints: {
            980: {
                perView: 1,
            },
        },
    }).mount();

    // add click event to each slide navigation item to control buttons
    controlBack?.addEventListener("click", () => {
        glide.go("<");
    });

    controlNext?.addEventListener("click", () => {
        glide.go(">");
    });
};
