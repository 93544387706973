import { initMoreInfo, openMoreInfo } from './request-form-more';
import { initPhoneMask, toggleSpinner } from './utils';
import { RequestFormValidation } from './request-form-validation';

export class RequestForm {
  private formValidation = new RequestFormValidation();
  private formInputs = document.querySelectorAll('.input-group[data-field_id]');
  private informationForm = document.querySelector('#informationForm') as HTMLFormElement;

  constructor() {
    if (!this.informationForm) return;
    this.initInformationForm();
    initMoreInfo();
    initPhoneMask();
    this.checkErrors();
  }

  private initInformationForm() {
    const submitButton = document.getElementById('submitButton') as HTMLButtonElement;

    this.formValidation.formChanged(this.informationForm, submitButton);
    this.informationForm.addEventListener('submit', (event) => {
      localStorage.setItem('formSubmitted', 'true');
      toggleSpinner();
    });
  }

  private checkErrors() {
    const formErrors = this.informationForm.querySelectorAll('.errorlist');
    if (formErrors.length > 0) {
      openMoreInfo();
      formErrors.forEach((error) => {
        const errorInput = error.previousElementSibling as HTMLInputElement;
        this.formValidation.showError(errorInput);
      });
    }
  }
}
