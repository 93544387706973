export const initAccordion = () => {
    const accordionHeaders = document.querySelectorAll(".accordion-header");

    accordionHeaders.forEach(function(header) {
        header.addEventListener("click", function() {
            const content = this.nextElementSibling;
            this.classList.toggle("active");
            content.classList.toggle("active");
        });
    });
}
