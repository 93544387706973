const blogList = document.querySelector('.blog-list');
const templateCard = document.querySelector('.blog-list-item');
const loadMoreButton = document.querySelector('.blog-list-load-more');
let currentPage = 1;

// TODO rework pagination when design is ready

export async function blogPagination(): Promise<void> {
  let allArticles: ArticleResponse[] = [];

  if (!blogList || !templateCard || !loadMoreButton) return;

  if (currentPage > 1) allArticles = await fetchAllArticlesUpToPage(currentPage);
  else checkForLoadMoreButton();

  if (allArticles.length > 0) addArticlesToBlogList(allArticles);

  loadMoreListener();
}

function loadMoreListener(): void {
  loadMoreButton.addEventListener('click', async () => {
    const params = new URLSearchParams();

    if (currentPage > 1) {
      params.set('page', currentPage.toString());
    } else {
      params.set('page', '2');
    }

    const response = await fetchArticles(params);

    if (response.next) {
      currentPage = parseInt(new URL(response.next).searchParams.get('page')!, 10);
    }

    addArticlesToBlogList([response]);

    if (!response.next) {
      loadMoreButton.classList.add('hidden');
    }
  });
}

const fetchArticles = async (params: URLSearchParams): Promise<ArticleResponse> => {
  const url = new URL('/api/web/blog/articles/', window.location.origin);
  params.forEach((value, key) => url.searchParams.append(key, value));

  const category_id = loadMoreButton.attributes.getNamedItem('data-category_id')?.value + '';
  url.searchParams.append('category_id', category_id);

  const resp = await fetch(url.toString(), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });
  return await resp.json();
};

async function fetchAllArticlesUpToPage(currentPage: number): Promise<ArticleResponse[]> {
  let allArticles: ArticleResponse[] = [];
  for (let page = 1; page <= currentPage; page++) {
    const params = page === 1 ? new URLSearchParams() : new URLSearchParams({ page: page.toString() });
    const response = await fetchArticles(params);
    allArticles.push({ ...response, results: response.results.splice(1, response.results.length) });
  }
  return allArticles;
}

const addArticlesToBlogList = (pages: ArticleResponse[]): void => {
  if (pages.length === 0) return;

  pages.forEach((articles) => {
    articles.results.forEach((article: Article) => {
      const cardClone = templateCard.cloneNode(true) as HTMLAnchorElement;

      if (pages[0].results.length > 2 || blogList.childNodes.length > 2) cardClone.classList.remove('full-width');

      cardClone.href = article.url;

      // image
      const img = cardClone.querySelector('.list-item-image') as HTMLImageElement;
      img.src = article.img.png.split(',')[0]; // update image src to match article's image
      const avif = cardClone.querySelector('.list-item-image-avif') as HTMLImageElement;
      avif.srcset = article.img.avif;
      const webp = cardClone.querySelector('.list-item-image-webp') as HTMLImageElement;
      webp.srcset = article.img.webp;
      const png = cardClone.querySelector('.list-item-image-png') as HTMLImageElement;
      png.srcset = article.img.png;

      // title
      const titleElement = cardClone.querySelector('.list-item-title') as HTMLElement;
      titleElement.textContent = article.title; // update title text to match article's title

      // description
      const descEl = cardClone.querySelector('.list-item-description');
      descEl.textContent = article.description.slice(0, 100) + '...';

      blogList.appendChild(cardClone);
    });
  });
};

async function checkForLoadMoreButton(): Promise<void> {
  const articles = await fetchArticles(new URLSearchParams());
  if (!articles.next) loadMoreButton.classList.add('hidden');
}

interface ArticleResponse {
  count: number;
  next: string;
  previous: string;
  results: Article[];
}

interface Article {
  title: string;
  last_published_at: string;
  description: string;
  category: string;
  category_url: string;
  img: {
    avif: string;
    webp: string;
    png: string;
  };
  url: string;
}
