import '../styles/style.scss';
import { initVideoAnimation, initChartAnimation } from './hero-section';
import { initWatchVideo } from './video-popup';
import { headerInIt } from './header';
import { initClientsSlider } from './clients-slider';
import { initSelect } from './select';
import { initAccordion } from './accordion';
import { blogPagination } from './blog';
import { initForm, initRequestDemo, initSlideshow } from './book-demo/request-demo';
import { initAboutUs } from './about-us';

if (process.env.NODE_ENV !== 'production') {
  console.log('Looks like we are in development mode!');
}

headerInIt();
blogPagination();

let domReady = (cb) => {
  document.readyState === 'interactive' || document.readyState === 'complete' ? cb() : document.addEventListener('DOMContentLoaded', cb);
};

domReady(() => {
  // Display body when DOM is loaded
  document.body.style.visibility = 'visible';
});

// landing check
if (document.body.dataset.page === 'home_homepage') {
  initVideoAnimation();
  initChartAnimation();
  initWatchVideo();
  initClientsSlider();
}

// request demo check
if (document.body.dataset.page === 'booking_demorequestpage') {
  initRequestDemo();
}

if (document.body.dataset.page === 'core_successpage') {
  initSlideshow();
}

if (document.body.dataset.page === 'releases_releaseindexpage' || document.body.dataset.page === 'releases_releasedetailpage') {
  initSelect();
}

if (document.body.dataset.page === 'home_industrypage') {
  initAccordion();
  initClientsSlider();
}

if (document.body.dataset.page === 'about_personbiopage') {
  initSlideshow();
}

if (document.body.dataset.page === 'blog_article') {
  initForm();
}

if (document.body.dataset.page === 'about_aboutuspage') {
  initAboutUs();
  initClientsSlider();
}
