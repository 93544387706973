export const header = document.querySelector('header.header');

const sections = document.querySelectorAll('section');
const navLi = document.querySelectorAll('.navigation-item');

export const headerInIt = () => {
  addHeaderScrolledClass();
  mobileNav();
  burgerMenu();
  setSubnavPosition();
};

window.onscroll = () => {
  let current: string = '';
  sections.forEach((section) => {
    const sectionTop = section.offsetTop;
    if (pageYOffset >= sectionTop - 60) {
      current = section.getAttribute('id');
    }
  });

  navLi.forEach((li) => {
    li.classList.remove('active');
    const href = li.querySelector('a').getAttribute('href');
    const hrefWithoutHash = href?.substring(2);
    if (hrefWithoutHash === current) {
      li.classList.add('active');
    }
  });
};

export const addHeaderScrolledClass = () => {
  window.addEventListener('scroll', () => {
    if (!header) return;
    if (window.scrollY > 0) {
      header.classList.add('header-scrolled');
    } else {
      header.classList.remove('header-scrolled');
    }
  });
};

export const burgerMenu = () => {
  const burger = document.querySelector('.burger') as HTMLElement;
  const nav = document.querySelector('.mob-navigation') as HTMLElement;
  if (!burger || !nav) return;
  burger.addEventListener('click', () => {
    nav.classList.toggle('navigation-active');
    burger.classList.toggle('burger-active');
    document.body.classList.toggle('disable-scroll');
  });
};

export const mobileNav = () => {
  const navLinks = document.querySelectorAll('.mob-navigation-item') as NodeListOf<HTMLElement>;
  const burger = document.querySelector('.burger') as HTMLElement;

  navLinks.forEach((activeLink) => {
    activeLink.addEventListener('click', () => {
      navLinks.forEach((link) => {
        if (link !== activeLink) {
          link.classList.remove('active');
        }
      });
      if (!activeLink.classList.contains('with-submenu')) burger.click();

      activeLink.classList.toggle('active');
    });
  });
};

export const setSubnavPosition = () => {
  const navItems = document.querySelectorAll('.navigation-item') as NodeListOf<HTMLElement>;

  navItems.forEach((navItem) => {
    const submenu = navItem.querySelector('.submenu') as HTMLElement;
    if (!submenu) return;

    const navItemRect = navItem.getBoundingClientRect();
    const submenuWidth = submenu.offsetWidth;
    const menuCenter = navItemRect.left + navItemRect.width / 2;
    let left = menuCenter - submenuWidth / 2;

    left = Math.max(150, left);
    left = Math.min(left, window.innerWidth - submenuWidth - 150);

    submenu.style.position = 'fixed';
    submenu.style.left = `${left}px`;
  });
};
