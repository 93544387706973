export const showArticleFormSuccess = () => {
  const informationForm = document.querySelector('#informationForm') as HTMLFormElement;
  const formErrors = informationForm?.querySelectorAll('.errorlist');
  const isSubmitted = localStorage.getItem('formSubmitted');
  const successArticle = document.querySelector('.article-form-success') as HTMLElement;
  const formBlock = document.querySelector('.article-form') as HTMLElement;
  if (formErrors?.length === 0 && isSubmitted === 'true') {
    successArticle.style.display = 'flex';
    formBlock.style.display = 'none';
    localStorage.removeItem('formSubmitted');
  }
};
