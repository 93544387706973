export const initSelect = () => {
    const datepickerSelect = document.querySelector('.datepicker-select');

    if (datepickerSelect) {
        datepickerSelect.addEventListener('click', (evt) => {
            (evt.currentTarget as HTMLElement).classList.toggle('active');
            (evt.currentTarget as HTMLElement).querySelector('.date-list').classList.toggle('hidden');

            document.addEventListener('click', (evt) => {
                if (!datepickerSelect.contains(evt.target as Node)) {
                    datepickerSelect.classList.remove('active');
                    datepickerSelect.querySelector('.date-list').classList.add('hidden');
                }
            });
        })
    }
}
