import { Dropdown } from '../dropdown';
import { Slideshow } from './slideshow';
import { RequestForm } from './request-form';
import { showArticleFormSuccess } from './show-article-form-success';
import { formDropdownsCleanup } from './form-dropdowns-cleanup';

export const initRequestDemo = () => {
  formDropdownsCleanup();
  new Dropdown();
  new Slideshow();
  new RequestForm();
};

export const initSlideshow = () => {
  new Slideshow();
};

export const initForm = () => {
  showArticleFormSuccess();
  new RequestForm();
};
