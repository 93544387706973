const watchVideoBtn = document.getElementById("watchVideoBtn");
const aboutVideoPreview = document.getElementById("aboutVideoPreview");
const eventPreviewList = document.querySelectorAll(".event-image-container");
const videoPopup = document.getElementById("videoPopup");
const videoPopupClose = document.getElementById("videoPopupClose");
const overlay = document.getElementById("overlay");
const videoFrame: any = document.getElementById("videoFrame");

export function initWatchVideo() {
    const listeners = [watchVideoBtn, aboutVideoPreview, ...eventPreviewList];
    overlay?.addEventListener("click", closePopup);
    videoPopupClose?.addEventListener("click", closePopup);
    listeners.forEach((title) =>
        title.addEventListener("click", openVideoPopup),
    );
}

const closePopup = () => {
    videoPopup?.classList.remove("active");
    videoFrame.innerHTML = "";
};

const openVideoPopup = (evt) => {
    const videoIframeTemplateId = evt.currentTarget.dataset.videoTemplate;
    if (videoIframeTemplateId === undefined) return;
    videoPopup?.classList.add("active");
    const iframe = (
        document.getElementById(videoIframeTemplateId) as any
    ).content.cloneNode(true);
    videoFrame?.appendChild(updateVideoFrame(iframe.children[0]));
    window.addEventListener("resize", () =>
        updateVideoFrame(videoFrame.querySelector("iframe")),
    );
    evt.preventDefault();
};

const updateVideoFrame = (iframe) => {
    const width = window.innerWidth - (window.innerWidth / 100) * 25;
    // Keep aspect ratio 16:9 for video
    iframe.setAttribute("width", width.toString());
    iframe.setAttribute("height", (width / (16 / 9)).toString());
    return iframe;
};
