export class RequestFormValidation {
  public formChanged(informationForm: HTMLFormElement, submitButton: HTMLElement) {
    const emailInput = document.querySelector('[type="email"]') as HTMLInputElement;
    const phoneInput = document.querySelector('[type="tel"]') as HTMLInputElement;

    informationForm.addEventListener('input', (evt) => {
      document.querySelector('.server-error').innerHTML = '';

      if (this.ifFormValid(emailInput, phoneInput)) {
        submitButton.removeAttribute('disabled');
      } else {
        submitButton.setAttribute('disabled', 'true');
      }
      if (this.checkEmailValid(emailInput.value) || this.checkEmailValid(emailInput.value) === null) {
        emailInput.classList.remove('invalid');
      } else {
        emailInput.classList.add('invalid');
      }
      if (this.isPhoneValid(phoneInput.value) || this.isPhoneValid(phoneInput.value) === null) {
        phoneInput.classList.remove('invalid');
      } else {
        phoneInput.classList.add('invalid');
      }
    });
  }

  private ifFormValid(emailInput: HTMLInputElement, phoneInput: HTMLInputElement) {
    const fullForm = document.querySelector('.additional-fields')?.classList.contains('active');
    return fullForm
      ? emailInput.value !== '' && this.checkEmailValid(emailInput.value) && this.isPhoneValid(phoneInput.value)
      : this.checkEmailValid(emailInput.value) && emailInput.value !== '';
  }

  private isPhoneValid(phone: string): boolean {
    if (phone.length === 0) {
      return true;
    }

    const phoneRegex = /\+1\s\(\d{3}\)\s\d{3}-\d{4}/;
    return String(phone).match(phoneRegex) !== null;
  }

  private checkEmailValid(email: string): boolean {
    if (email.length === 0) {
      return null;
    }
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return String(email).toLowerCase().match(emailRegex) !== null;
  }

  public showError(errorInput: HTMLInputElement) {
    const errorList = errorInput.nextElementSibling as HTMLUListElement;
    errorList.classList.add('active');
    errorInput.classList.add('invalid');
    errorInput.addEventListener('input', () => {
      errorList.classList.remove('active');
      errorInput.classList.remove('invalid');
      (errorInput.nextElementSibling as HTMLElement).style.display = 'none';
    });
  }
}
