export const initVideoAnimation = () => {
    const video = document.querySelector(".hero-video") as HTMLVideoElement;
    const marker = document.querySelector(".marker");
    const map = document.querySelector(".hero-map-image");

    const markerObserver = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
            video.play();
            marker.classList.add('animate');
            map.classList.add('animate');
        }
    });

    if (marker) markerObserver.observe(marker);

    if (window.innerWidth < 1440) {
        video.play();
    }
};

export const initChartAnimation = () => {
    const chartsContainer = document.querySelector(".hero-maintenance-circles");
    const oilPath = document.querySelector(".oil-path") as SVGPathElement;
    const greasePath = document.querySelector(".grease-path") as SVGPathElement;

    const oilPathLength = oilPath.getTotalLength();
    const greasePathLength = greasePath.getTotalLength();

    oilPath.style.strokeDasharray = `${oilPathLength} ${oilPathLength}`;
    oilPath.style.strokeDashoffset = `${oilPathLength}`;

    greasePath.style.strokeDasharray = `0 ${greasePathLength}`;
    greasePath.style.strokeDashoffset = `0`;

    const duration = 300;
    const oilPathChangePerFrame = oilPathLength / duration;
    const greasePathChangePerFrame = greasePathLength / duration;

    const chartsObserver = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    let oilPathRemaining = oilPathLength;
                    let greasePathDrawn = 0;
                    const animate = () => {
                        oilPathRemaining -= oilPathChangePerFrame;
                        greasePathDrawn += greasePathChangePerFrame;

                        oilPath.style.strokeDashoffset = Math.max(oilPathRemaining, 0).toString();
                        greasePath.style.strokeDasharray = `${Math.min(greasePathDrawn, greasePathLength)} ${greasePathLength}`;

                        if (oilPathRemaining > 0 || greasePathDrawn < greasePathLength) {
                            window.requestAnimationFrame(animate);
                        }
                    };
                    window.requestAnimationFrame(animate);
                    chartsObserver.disconnect();
                }
            });
        },
        {
            rootMargin: "-100px 0px 0px 0px",
            threshold: 0
        });

    if (chartsContainer) chartsObserver.observe(chartsContainer)
};
