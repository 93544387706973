export class Slideshow {
    private readonly slides: NodeListOf<HTMLElement>;
    private readonly dots: NodeListOf<HTMLElement>;
    private readonly prevButton: HTMLElement | null;
    private readonly nextButton: HTMLElement | null;
    private slideIndex = 1;
    private interval

    constructor() {
        this.slides = document.querySelectorAll(".image-container") as NodeListOf<HTMLElement>;
        this.dots = document.querySelectorAll(".dot") as NodeListOf<HTMLElement>;
        this.prevButton = document.querySelector(".arrow-left");
        this.nextButton = document.querySelector(".arrow-right");

        if (this.prevButton)
            this.prevButton.addEventListener("click", () => {
                this.updateSlide(-1);
                this.resetInterval();
            });

        if (this.nextButton)
            this.nextButton.addEventListener("click", () => {
                this.updateSlide(1);
                this.resetInterval();
            });

        if (this.dots)
            this.dots.forEach((dot, index) => {
                dot.addEventListener("click", () => {
                    this.showSlides(this.slideIndex = index + 1);
                    this.resetInterval();
                });
            })

        this.showSlides(this.slideIndex);

        this.interval = setInterval(() => {
            this.updateSlide(1)
        }, 5000);
    }

    private updateSlide(n: number) {
        this.showSlides(this.slideIndex += n);
    }

    private showSlides(n: number) {
        if (n > this.slides.length) {
            this.slideIndex = 1;
        }
        if (n < 1) {
            this.slideIndex = this.slides.length;
        }

        for (let i = 0; i < this.slides.length; i++) {
            this.slides[i].classList.remove("visible");
        }

        for (let i = 0; i < this.dots.length; i++)
            this.dots[i].className = this.dots[i].className.replace(" active", "");

        if (this.slides[this.slideIndex - 1]) {
            this.slides[this.slideIndex - 1].classList.add("visible");
        }

        if (this.dots[this.slideIndex - 1])
            this.dots[this.slideIndex - 1].className += " active";
    }

    private resetInterval() {
        clearInterval(this.interval);
        this.interval = setInterval(() => {
            this.updateSlide(1)
        }, 5000);
    }
}
